// File: src/components/statistics/TokenCard.jsx
import React, { useEffect, useState } from 'react';
import { Card, Title, Text, Metric, Badge } from "@tremor/react";
import PropTypes from 'prop-types';
import { formatNumber } from '../utils/format';
import { generatePriceAlerts } from '../utils/notificationHelper';

export const TokenCard = React.memo(({ 
  token, 
  lastSellPrice,
  onAlert 
}) => {
  // Lấy notification settings từ localStorage
  const [notificationSettings] = useState(() => {
    try {
      const saved = localStorage.getItem('notificationSettings');
      return saved ? JSON.parse(saved) : {
        enabled: true,
        dcaThreshold: 5,
        dcaAlertInterval: 60,
        profitThreshold: 5,
        profitAlertInterval: 60,
        lastSellDropThreshold: 5,
        lastSellAlertInterval: 60
      };
    } catch (error) {
      console.error('Error loading notification settings:', error);
      return null;
    }
  });

  // Lấy last alert times từ localStorage
  const [lastAlertTimes] = useState(() => {
    try {
      const saved = localStorage.getItem('lastAlertTimes');
      return saved ? JSON.parse(saved) : {};
    } catch (error) {
      console.error('Error loading last alert times:', error);
      return {};
    }
  });
  
  useEffect(() => {
    if (!notificationSettings || !notificationSettings.enabled) return;

    const alerts = generatePriceAlerts(
      token, 
      lastSellPrice,
      notificationSettings,
      lastAlertTimes
    );
    
    if (alerts.length > 0 && onAlert) {
      alerts.forEach(alert => onAlert(alert));
    }
  }, [token, lastSellPrice, notificationSettings, lastAlertTimes, onAlert]);
  
  // Tính toán chỉ số so sánh
  const priceCompareAvg = token.remainingTokens > 0 
    ? ((token.currentPrice - token.averageBuyPrice) / token.averageBuyPrice) * 100
    : null;

  // Tính toán unrecovered investment
  const unrecoveredInvestment = token.usdtReceived - token.usdtSpent;
  
  return (
    <Card className="space-y-4">
      {/* Token Header */}
      <div className="flex justify-between items-center">
        <Title>{token.symbol}</Title>
        <Text 
          className={`font-bold ${
            (token.totalProfitLoss || 0) > 0 ? 'text-green-600' : 
            (token.totalProfitLoss || 0) < 0 ? 'text-red-600' : 
            'text-gray-600'
          }`}
        >
          {(token.totalProfitLoss >= 0 ? '+' : '') + formatNumber(token.totalProfitLoss)} USDT
        </Text>
      </div>

      {/* Price Alerts & Badges */}
      <div className="space-y-2">
        {token.remainingTokens > 0 ? (
          <div className="flex items-center space-x-2">
            <Badge 
              color={priceCompareAvg >= 0 ? "green" : "blue"}
              size="sm"
            >
              {priceCompareAvg >= 0 ? "Profit" : "DCA"} Zone
            </Badge>
            <Text 
              className={priceCompareAvg >= 0 ? "text-green-600" : "text-blue-600"}
            >
              {priceCompareAvg >= 0 ? "+" : ""}{priceCompareAvg.toFixed(2)}%
            </Text>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <Badge color="gray" size="sm">
              No Holdings
            </Badge>
          </div>
        )}
      </div>

      {/* Trading Information */}
      <div className="pt-4 border-t space-y-4">
        {/* Tokens Bought & Sold */}
        <div className="flex justify-between items-center">
          <div>
            <Text>Tokens Bought</Text>
            <Metric className="text-blue-600">
              {formatNumber(token.tokensBought)}
            </Metric>
            <Text className="text-sm text-gray-500">
              {formatNumber(token.usdtSpent)} USDT
            </Text>
          </div>
          <div className="text-right">
            <Text>Tokens Sold</Text>
            <Metric className="text-red-600">
              {formatNumber(token.tokensSold)}
            </Metric>
            <Text className="text-sm text-gray-500">
              {formatNumber(token.usdtReceived)} USDT
            </Text>
          </div>
        </div>

        {/* Tokens Remaining & Current Price */}
        <div className="flex justify-between items-center">
          <div>
            <Text>Tokens Remaining</Text>
            <Metric className="text-green-600">
              {formatNumber(token.remainingTokens)}
            </Metric>
          </div>
          <div className="text-right">
            <Text>Current Price</Text>
            <Metric 
              className={`${
                token.currentPrice >= token.averageBuyPrice ? 'text-green-600' : 'text-red-600'
              }`}
            >
              {formatNumber(token.currentPrice, 4)} USDT
            </Metric>
          </div>
        </div>
         {/* Unrecovered Investment & ATH Price*/}
        <div className="flex justify-between items-center">
		  <div>
			<Text>{unrecoveredInvestment >= 0 ? "Realized Profit" : "Unrealized Loss"}</Text>
			<Metric className={unrecoveredInvestment >= 0 ? "text-green-600" : "text-red-600"}>
			  {(unrecoveredInvestment >= 0 ? "+" : "") + formatNumber(unrecoveredInvestment)} USDT
			</Metric>
		  </div>
		  <div className="text-right">
			<Text>ATH Price</Text>
			<Metric className="text-purple-600">
			  {formatNumber(token.ath, 4)} USDT
			</Metric>
			<Text className="text-xs text-gray-500">
			  {token.athTime ? new Date(token.athTime).toLocaleString() : 'N/A'}
			</Text>
		  </div>
		</div>
        {/* Avg Buy Price & Current Value */}
        <div className="flex justify-between items-center">
          <div>
            <Text>Avg Buy Price</Text>
            <Metric className="text-blue-600">
              {formatNumber(token.averageBuyPrice, 4)} USDT
            </Metric>
          </div>
          <div className="text-right">
            <Text>Current Value</Text>
            <Metric className={token.currentValue > 0 ? 'text-green-600' : 'text-gray-600'}>
              {formatNumber(token.currentValue)} USDT
            </Metric>
          </div>
        </div>
      </div>

      {/* Last Transactions Information */}
      <div className="pt-4 border-t text-sm text-gray-500 space-y-2">
        {/* Last Buy Info */}
        <div className="space-y-1">
          {lastSellPrice?.lastBuy ? (
            <>
			<div className="flex justify-between">
                <Text className="font-medium">Last Buy:</Text>
                <Text>{new Date(lastSellPrice.lastBuy.time).toLocaleString()}</Text>
            </div>
            <div className="flex justify-between">
                <Text>Price:</Text>
                <Text>{formatNumber(lastSellPrice.lastBuy.price, 4)} USDT</Text>
            </div>
            <div className="flex justify-between">
                <Text>Quantity:</Text>
                <Text>{formatNumber(lastSellPrice.lastBuy.quantity, 4)}</Text>
            </div>
            </>
          ) : (
            <Text className="text-gray-400">N/A</Text>
          )}
        </div>

        {/* Last Sell Info */}
        <div className="space-y-1">
          {lastSellPrice?.lastSell ? (
            <>
			<div className="flex justify-between">
                <Text className="font-medium">Last Sell:</Text>
                <Text>{new Date(lastSellPrice.lastSell.time).toLocaleString()}</Text>
              </div>
              <div className="flex justify-between">
                <Text>Price:</Text>
                <Text>{formatNumber(lastSellPrice.lastSell.price, 4)} USDT</Text>
              </div>
              <div className="flex justify-between">
                <Text>Quantity:</Text>
                <Text>{formatNumber(lastSellPrice.lastSell.quantity, 4)}</Text>
              </div>
            </>
          ) : (
            <Text className="text-gray-400">N/A</Text>
          )}
        </div>
      </div>
    </Card>
  );
});

TokenCard.propTypes = {
  token: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    tokensBought: PropTypes.number.isRequired,
    tokensSold: PropTypes.number.isRequired,
    remainingTokens: PropTypes.number.isRequired,
    usdtSpent: PropTypes.number.isRequired,
    usdtReceived: PropTypes.number.isRequired,
    averageBuyPrice: PropTypes.number.isRequired,
    currentPrice: PropTypes.number.isRequired,
    currentValue: PropTypes.number.isRequired,
    totalProfitLoss: PropTypes.number.isRequired,
	ath: PropTypes.number, // Thêm ATH
    athTime: PropTypes.string // Thêm thời gian đạt ATH
  }).isRequired,
  onAlert: PropTypes.func.isRequired,
  lastSellPrice: PropTypes.shape({
    lastSell: PropTypes.shape({
      price: PropTypes.number,
      quantity: PropTypes.number,
      time: PropTypes.string
    }),
    lastBuy: PropTypes.shape({
      price: PropTypes.number,
      quantity: PropTypes.number,
      time: PropTypes.string
    })
  })
};

TokenCard.displayName = 'TokenCard';

export default TokenCard;