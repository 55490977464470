// components/EnhancedLogs.jsx
import React, { useState, useEffect } from 'react';
import { Card, Button } from "@tremor/react";
import { api } from '../services/api';
import { StatusCard } from './logs/StatusCard';
import { LogsViewer } from './logs/LogsViewer';

export const EnhancedLogs = () => {
  // Bot status state
  const [botStatus, setBotStatus] = useState({
    isRunning: false,
    lastScan: null,
    totalSymbols: 0,
    pendingMessages: 0
  });

  // Logs state
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Clear logs state
  const [clearingLogs, setClearingLogs] = useState(false);
  const [daysToKeep, setDaysToKeep] = useState(30);

  // Fetch bot status
  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await api.getStatus();
        setBotStatus(response.data);
      } catch (error) {
        console.error('Error fetching bot status:', error);
        setError('Error fetching bot status');
      }
    };

    fetchStatus();
    const interval = setInterval(fetchStatus, 5000);
    return () => clearInterval(interval);
  }, []);

  // Fetch logs handler
  const fetchLogs = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.getLogs({
        limit: 100
      });
      setLogs(response.data);
    } catch (error) {
      setError(error.response?.data?.message || 'Error fetching logs');
    } finally {
      setLoading(false);
    }
  };

  // Clear logs handler
  const handleClearLogs = async () => {
  if (!window.confirm(`Are you sure you want to delete logs older than ${daysToKeep} days?`)) {
    return;
  }

  setClearingLogs(true);
  try {
    const response = await api.clearLogs(daysToKeep); // Sử dụng method rõ ràng
    console.log('Clear logs response:', response.data); // Debug log
    alert(response.data.message);
    await fetchLogs(); // Refresh logs sau khi xóa
  } catch (error) {
    console.error('Error clearing logs:', error); // Debug log
    setError(error.response?.data?.message || 'Error clearing logs');
  } finally {
    setClearingLogs(false);
  }
};

  return (
    <div className="p-4 space-y-6">
      {/* Status Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <StatusCard
          title="Bot Status"
          value={botStatus.isRunning ? "Running" : "Stopped"}
          subtitle={`Last Scan: ${botStatus.lastScan ? new Date(botStatus.lastScan).toLocaleString() : 'N/A'}`}
          decorationColor={botStatus.isRunning ? "green" : "red"}
          isRunning={botStatus.isRunning}
        />
        <StatusCard
          title="System Status" 
          value={`${botStatus.totalSymbols} Symbols`}
          subtitle={`Pending Messages: ${botStatus.pendingMessages}`}
          decorationColor="blue"
          isRunning={botStatus.isRunning}
        />
      </div>

      {/* Logs Section */}
      <Card>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-4">
            <Button 
              onClick={fetchLogs}
              loading={loading}
              variant="primary"
              color="blue"
              size="sm"
            >
              Get Logs
            </Button>

            <div className="flex items-center space-x-2">
              <span className="text-gray-600">Keep logs for last</span>
              <input 
                type="number"
                value={daysToKeep}
                onChange={e => setDaysToKeep(parseInt(e.target.value))}
                min="1"
                max="365"
                className="w-20 px-2 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <span className="text-gray-600">days</span>
              
              <Button
                onClick={handleClearLogs}
                loading={clearingLogs}
                variant="secondary"
                color="red"
                size="sm"
              >
                Clear Old Logs
              </Button>
            </div>
          </div>
        </div>

        <LogsViewer 
          logs={logs}
          loading={loading}
          onRefresh={fetchLogs}
        />
      </Card>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      )}
    </div>
  );
};

export default EnhancedLogs;